<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }"
            :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="选择日期:"
                  style="width: 500px">
        <a-range-picker v-model="gmtInvite"
          :disabled-date="disabledDate"
          @change="onChange1"
          format="YYYY-MM-DD"
          valueFormat="YYYY-MM-DD"
          style="width: 400px" />
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.phone" placeholder="账号"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table
      :data-source="list"
      :rowKey="record => record.id"
      :columns="columns"
      :pagination="pagination"
      bordered
    >
    </a-table>
  </div>
</template>

<script>
import moment from 'moment'
import Breadcrumb from '@/components/breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: this.$route.params.breadName,
          url: '/sale/invitation/stageStatistics'
        },
        {
          name: '邀请的驿站信息',
          url: ''
        }
      ],
      isQueryLoading: false,
      gmtInvite: [],
      form: {
        phone: '',
        gmtInviteStart: null,
        gmtInviteEnd: null,
        inviteId: this.$route.params.inviteId,
        inviteScene: this.$route.params.inviteScene
      },
      columns: [
        { title: '驿站账号', width: 200, key: 'phone', dataIndex: 'phone' },
        { title: '名称', width: 300, key: 'name', dataIndex: 'name' },
        { title: '完成订单数', width: 200, key: 'totalOrder', dataIndex: 'totalOrder' },
        { title: '完结订单数', width: 200, key: 'totalComplete', dataIndex: 'totalComplete' },
        { title: '注册时间', width: 200, key: 'gmtCreate', dataIndex: 'gmtCreate' }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.gmtInvite = this.$route.params.gmtInvite
    if (this.gmtInvite != null && this.gmtInvite.length > 0) {
      if (this.gmtInvite[0]) {
        this.form.gmtInviteStart = this.gmtInvite[0] + ' 00:00:00'
      }
      if (this.gmtInvite[1]) {
        this.form.gmtInviteEnd = this.gmtInvite[1] + ' 23:59:59'
      }
    }
    this.form.inviteId = this.$route.params.inviteId
    this.form.inviteScene = this.$route.params.inviteScene
    this.reloadList()
  },
  methods: {
    moment,
    // 重置表单
    resetForm (formName) {
      this.form.phone = ''
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {
    }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {
    }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryBeInvitedStageList',
        query: {
          inviteScene: this.form.inviteScene
        },
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.reloadList()
    },
    onChange1 (data) {
      this.form.gmtInviteStart = data[0] + ' 00:00:00'
      this.form.gmtInviteEnd = data[1] + ' 23:59:59'
    },
    disabledDate (current) {
      return current && current > moment().endOf('day')
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
